import React, { useState, useRef } from 'react';
// import gsap from 'gsap';
import './Navbar2.css';
import 'remixicon/fonts/remixicon.css';
import { Link, useLocation } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';

export default function Navbar2() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const [commitmentsOpen, setCommitmentsOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);

  const toggleCommitmentsDropdown = () => {
    setCommitmentsOpen(!commitmentsOpen);
  };

  const toggleResourcesDropdown = () => {
    setResourcesOpen(!resourcesOpen);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'noHover coloredElement rounded-md p-1 px-2' : '';
  };

  return (
    <div className="fixed z-[100] bg-[rgba(0,0,0,0.3)] top-0 w-full bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40">
      {/* Navbar */}
      <nav className="bg-transparent text-white w-full px-4 md:px-8 h-20 flex justify-between items-center">
        <div className="flex relative w-screen lg:w-0 items-center justify-center space-x-4">
          {/* Hamburger icon for mobile */}
          <button
            className="lg:hidden absolute left-0 mr-4 z-50 focus:outline-none"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <svg
              className="w-8 h-8 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
          <div className="lg:hidden smallScreenLogo" >
            <img src="/logo.png" alt="Logo" style={{width: "17%", display: "inline" , justifyContent:"end"}} />
          </div>
        </div>
        {/* Navbar links for larger screens */}
        <div className="hidden lg:flex lg:w-full  items-center justify-between">
          <div className="flex leftLinks">
          <a className={getNavLinkClass("/esports.udghosh.org.in")} href="http://esports.udghosh.org.in">Esports</a>
            <a className={getNavLinkClass("/events.udghosh.org.in")} href="https://events.udghosh.org.in">Competitions</a>
            <a className={getNavLinkClass("/gallery")} href="/gallery">Gallery</a>
            <a className={getNavLinkClass("/sponsors/")} href="/sponsors">Sponsors</a>
          </div>
          {/* Logo */}
          <div id="logo" className="flex-grow flex justify-center w-64">
            {/* <div className='typefaceHref1' style={{width: "20%"}}><img src="BTgKKB9yc (1).png" alt="Logo" className='typeface' /></div> */}
            <a className='typefaceHref' style={{width: "5vw"}} href="/home"><img src="/logo.png" alt="Logo" className='typeface' /></a>
            {/* <div className='typefaceHref2' style={{width: "20%"}}><img src="BTgKKB9yc.png" alt="Logo" className='typeface' /></div> */}
          </div>
          {/* Dropdowns */}
          <div className="flex rightLinks">
            <div className="relative">
              <button
                className="menu__link flex items-center gap-2 focus:outline-none text-white text-lg"
                onClick={toggleCommitmentsDropdown}
              >
                Commitments{" "}
                {commitmentsOpen ? (
                  <i className="dropdownIcon fa fa-chevron-up" />
                ) : (
                  <i className="dropdownIcon fa fa-chevron-down" />
                )}
              </button>
              {commitmentsOpen && (
                <div className="absolute z-50 left-0 mt-2 w-48   bg-[rgba(0,0,0,0.5)] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40 rounded-md shadow-lg">
                  <a className="block px-4 py-2 text-gray-200 hover:bg-[rgba(0,0,0,0.5)] hover:rounded-t-md" href="/antique">Antique</a>
                  <a className="block px-4 py-2 text-gray-200 hover:bg-[rgba(0,0,0,0.5)]" href="/social">Social Initiatives</a>
                  <a className="block px-4 py-2 text-gray-200 hover:bg-[rgba(0,0,0,0.5)] hover:rounded-b-md" href="/vision">Vision</a>
                </div>
              )}
            </div>
            
            <a className={getNavLinkClass("/esports.udghosh.org.in")} href="https://ca.udghosh.org.in/">CA Portal</a>
            <a className={getNavLinkClass("/esports.udghosh.org.in")} href="https://unosq.udghosh.org.in/">UNOSQ</a>
            <a className={getNavLinkClass("/teams")} href="/teams">Team</a>
          </div>
        </div>
      </nav>

      {/* Sidebar for mobile */}
      <div className={`fixed h-full inset-y-0 left-0 transform ${sidebarOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out bg-[rgba(0,0,0,0.7)] w-72 z-[100]`}>
        <div className="flex justify-between items-center mb-6 px-4 pt-4">
          <div className="text-white text-2xl font-extrabold">UDGHOSH</div>
          <button
            onClick={() => setSidebarOpen(false)}
            className="text-white focus:outline-none"
          ><i class="ri-close-large-fill"></i></button>
        </div>
        <ul className="space-y-6 p-4 pb-8 bg-[rgba(0,0,0,0.8)] h-screen text-white">
          <li>
            <a className='block text-lg' href="/home">Home</a>
          </li>
          <li>
            <a className='block text-lg' href="https://events.udghosh.org.in">Competitions</a>
          </li>
          <li>
            <a className='block text-lg' href="/gallery">Gallery</a>
          </li>
          <li>
            <a className='block text-lg' href="/sponsors">Sponsors</a>
          </li>
          <li>
            <a className='block text-lg' href="http://esports.udghosh.org.in">Esports</a>
          </li>
          <li>
            <a className='block text-lg' href="/social">Social Initiatives</a>
          </li>
          <li>
            <a className='block text-lg' href="/antique">Antique</a>
          </li>
          <li>
            <a className='block text-lg' href="/teams">Teams</a>
          </li>
          {/* <li>
            <button className="bg-green-400 text-black px-4 py-2 rounded w-full">Sign In</button>
          </li> */}
        </ul>
      </div>

      {/* Overlay when sidebar is open */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
}
